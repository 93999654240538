<template>
    <div>
		<div class="modal-header">
		    <div class="modal-title">
		        <a-icon type="medicine-box" style="margin-right:10px" />
		        <span>{{l('Copy')}}</span>
		    </div>
		</div>
		<div>
		    <a-config-provider :locale="zh_CN">
				<a-row>
				    <a-col :span="24">
						<a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }"  :label="l('Name')">
							<h4 style="text-align: left;">{{oldname}}</h4>
						</a-form-item>
				    </a-col>
					<a-col :span="24">
					    <a-form-item :label-col="{ span: 7 }" :wrapper-col="{ span: 17 }" :label="l('NewTemplateName')">
					    	<a-input v-model="newname"></a-input>
					    </a-form-item>
					</a-col>
				</a-row>
			</a-config-provider>
		</div>
		<div class="modal-footer">
		    <a-button @click="close">
		        {{l('Cancel')}}
		    </a-button>
		    <a-button :type="'primary'" @click="save">
		        {{l('Save')}}
		    </a-button>
		</div>
	</div>
</template>
<script>
    import zh_CN from 'ant-design-vue/lib/locale/zh_CN';
    import ModalComponentBase from "../../../../shared/component-base/modal-component-base";
    import {AppConsts} from "@/abpPro/AppConsts";
    import axios from 'axios';
    import {AuditItemTemplateServiceProxy} from "../../../../shared/service-proxies";

    let _this;
    export default {
        name: 'copy',
        mixins: [ModalComponentBase],
        components: {
            axios
        },
        created() {
            _this = this;
            this.fullData();
            this.AuditItemTemplateServiceProxy = new AuditItemTemplateServiceProxy(this.$apiUrl, this.$api);
        },
        mounted() {
			this.getData();
		},
        data() {
            return {
                zh_CN,
                id:undefined,
                oldname:undefined,
                newname: undefined,
                isclick: true,
            }
        },
        methods: {
            getData() {
				this.isLoading = true;
				this.AuditItemTemplateServiceProxy.getForEdit(
					this.id
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					this.oldname = res.name;
					this.newname = res.name + this.l('Replica');
				})
				
			},
			save() {
			    if (!this.newname) {
			        abp.message.info(this.l('The_new_template_name_cannot_be_empty'));
			        return;
			    }
				
				if(this.isclick) {
					this.isclick = false;
					this.AuditItemTemplateServiceProxy.copy(
						this.id,
                        this.newname
					).finally(() => {
					    this.close()
					}).then(res => {
						this.isclick = true;
					    this.success(true)
					})
				}
			
			},
            
            
        }
    }
</script>
<style scoped>

</style>